import {
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { Account } from 'models/Account';
import { RootState } from 'store';
import { apiGet } from 'utils/request';
import { setAllServices } from '../services';

export const getAccount = createAsyncThunk('ui/getAccount', async (_, thunkApi) => {
  const account: Account = await apiGet('/accounts/me?responseType=full', {
    action: 'getAccount',
  });
  thunkApi.dispatch(
    setAllServices(account.services.filter((s) => s.status !== 'DELETED')),
  );
  return account;
});

interface State {
  account: Account | null;
  isLoading: boolean;
  error: SerializedError | null;
}

const initialState: State = {
  account: null,
  isLoading: false,
  error: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccount.fulfilled, (state, action: PayloadAction<Account>) => {
      state.isLoading = false;
      state.error = null;
      state.account = action.payload;
    });
    builder.addCase(getAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const selectAccount = (state: RootState) => state.account.account;

export const reducer = accountSlice.reducer;
