import {
  createAsyncThunk,
  createSelector,
  createSlice,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Meta } from 'models/Meta';
import { Service } from 'models/Service';
import { RootState } from 'store';
import { apiGet } from 'utils/request';

interface FetchServicesPayload {
  offset?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  responseType?: 'ids' | 'shallow' | 'selected' | 'full';
  includeOrigins?: boolean;
  showDeactivatedServices?: boolean;
}

export const fetchServices = createAsyncThunk(
  'services/fetchServices',
  async ({
    offset = 0,
    limit = 1000,
    responseType,
    // includeOrigins,
    search,
    sortBy,
    showDeactivatedServices = true,
  }: FetchServicesPayload | undefined = {}) => {
    let requestURL = `/services?offset=${offset}&limit=${limit}`;

    if (responseType) {
      requestURL += `&responseType=${responseType}`;
    }
    // if (includeOrigins) {
    //   requestURL += `&includeOrigins=${includeOrigins}`;
    // }
    if (search) {
      requestURL += `&search=${search}`;
    }
    if (sortBy) {
      requestURL += `&sortBy=${sortBy}`;
    }
    if (!showDeactivatedServices) {
      requestURL += `&status=ACTIVE`;
    }

    const data = await apiGet<{ data: Service[]; meta: Meta }>(requestURL, {
      action: 'getServices',
    });

    return data;
  },
);

export const fetchSingleService = createAsyncThunk(
  'services/fetchSingleService',
  async (id: string) => {
    const data = await apiGet<Service>(
      `/services/${id}?responseType=full&includeOrigins=true`,
      {
        action: 'getService',
      },
    );

    return data;
  },
);

interface State {
  services: Service[];
  fetching: boolean;
  currentService: Service | null;
  fetchingSingle: boolean;
  error: SerializedError | null;
  meta: Meta | null;
  allServices: Service[];
}

const initialState: State = {
  services: [],
  fetching: false,
  currentService: null,
  fetchingSingle: false,
  error: null,
  meta: null,
  allServices: [],
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setCurrentService(state, action: PayloadAction<Service>) {
      state.currentService = action.payload;
    },
    setAllServices(state, action: PayloadAction<Service[]>) {
      state.allServices = action.payload;
    },
    dropState() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchServices.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.fetching = false;
      state.error = null;
      state.services = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchServices.rejected, (state, action) => {
      state.fetching = false;
      state.error = action.error;
    });

    builder.addCase(fetchSingleService.pending, (state, action) => {
      state.fetchingSingle = true;
    });
    builder.addCase(fetchSingleService.fulfilled, (state, action) => {
      state.fetchingSingle = false;
      state.error = null;
      state.currentService = action.payload;
    });
    builder.addCase(fetchSingleService.rejected, (state, action) => {
      state.fetchingSingle = false;
      state.error = action.error;
    });
  },
});

export const selectServicesForUser = createSelector(
  [
    (state: RootState) => state.services.allServices,
    (state: RootState) => state.auth.user,
  ],
  (allServices = [], user) => {
    if (user.permissions.includes('P_SERVICE_ALL')) {
      return allServices;
    } else {
      return allServices?.filter((s) => user.services.includes(s._id)) || [];
    }
  },
);

export const selectServices = (state: RootState) => state.services.services;

export const selectServicesFetching = createSelector(
  [
    (state: RootState) => state.services.fetching,
    (state: RootState) => state.account.isLoading,
  ],
  (fetching, loading) => {
    return fetching || loading;
  },
);

export const selectServicesError = (state: RootState) => state.services.error;
export const selectServicesCount = (state: RootState) => state.services.meta?.count;

export const selectTotalServicesCount = createSelector(
  selectServicesForUser,
  (services) => services.length,
);

export const selectServicesWithSortStatus = createSelector(selectServices, (services) =>
  services.map((service) => {
    const serviceWithSortStatus = { ...service };
    if (service.status === 'DEACTIVATED') {
      serviceWithSortStatus.sortStatus = 'DEACTIVATED';
    } else if (service.pendingConfiguration) {
      serviceWithSortStatus.sortStatus = 'PENDING CONFIGURATION';
    } else {
      serviceWithSortStatus.sortStatus = 'ACTIVE';
    }
    return serviceWithSortStatus;
  }),
);

export const selectServicesSortedByName = createSelector(
  selectServicesForUser,
  (services) =>
    [...services].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
    ),
);

export const selectServicesWithPlaceholder = createSelector(
  selectServicesForUser,
  (services) => [{ _id: 'all', name: '(All Services)' } as Service].concat(services),
);

export const selectServicesCurrent = (state: RootState) => state.services.currentService;
export const selectServicesFetchingSingle = (state: RootState) =>
  state.services.fetchingSingle;

export const setCurrentService = servicesSlice.actions.setCurrentService;
export const setAllServices = servicesSlice.actions.setAllServices;
export const dropState = servicesSlice.actions.dropState;

export const reducer = servicesSlice.reducer;
