import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { apiPut } from 'utils/request';

export const disableOnboarding = createAsyncThunk(
  'ui/disableOnboarding',
  async (_, { dispatch }) => {
    await apiPut(
      '/users/me',
      {
        walkthroughVisible: false,
      },
      {
        action: 'updateWalkthrough',
      },
    );
    const auth = JSON.parse(sessionStorage.getItem('auth')!);
    sessionStorage.setItem(
      'auth',
      JSON.stringify({
        ...auth,
        user: {
          ...auth.user,
          walkthroughVisible: false,
        },
      }),
    );
    dispatch(toggleShowOnboarding(false));
  },
);

interface State {
  showOnboarding: boolean;
}

const auth = JSON.parse(sessionStorage.getItem('auth') || 'null');

const initialState: State = {
  showOnboarding: auth?.user.walkthroughVisible || false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleShowOnboarding(state, action: PayloadAction<boolean>) {
      state.showOnboarding = action.payload;
    },
  },
});

export const selectShowOnboarding = (state: RootState) => state.ui.showOnboarding;

export const { toggleShowOnboarding } = uiSlice.actions;
export const reducer = uiSlice.reducer;
