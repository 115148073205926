import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { ServiceOptions } from 'models/ServiceOptions';
import { PromoOption, ServiceOptionsMetadata } from 'models/ServiceOptionsMetadata';
import { RootState } from 'store';
import { apiGet } from 'utils/request';

export const fetchServiceOptionsAndMetadata = createAsyncThunk(
  'serviceOptions/fetchServiceOptionsAndMetadata',
  async (serviceId: string) => {
    const [options, { data }, { data: promos }]: [
      ServiceOptions,
      { data: ServiceOptionsMetadata[] },
      { data: PromoOption[] },
    ] = await Promise.all([
      apiGet(`/services/${serviceId}/options`, {
        action: 'getServiceOptions',
      }),
      apiGet(`/services/${serviceId}/options/metadata`, {
        action: 'getServiceOptionsMetadata',
      }),
      apiGet(`/services/${serviceId}/options/promo`, {
        action: 'getServiceOptionsPromo',
      }),
    ]);

    let metadata = data;
    if (!options.ftp) {
      metadata = (data as ServiceOptionsMetadata[]).filter(
        (d) => !d.template.includes('FTP'),
      );
    }

    return {
      options,
      metadata,
      promos: promos.map((p) => ({
        ...p,
        title: p.title || p.name,
      })),
    };
  },
);

export const fetchServiceOptions = createAsyncThunk(
  'serviceOptions/fetchServiceOptions',
  async (serviceId: string) => {
    const data = (await apiGet(`/services/${serviceId}/options`, {
      action: 'getServiceOptions',
    })) as ServiceOptions;

    return data;
  },
);

interface State {
  metadata: ServiceOptionsMetadata[];
  options: null | ServiceOptions;
  promos: PromoOption[];
  isOptionsAndMetadataLoading: boolean;
  isOptionsLoading: boolean;
  error: SerializedError | null;
}

const initialState: State = {
  metadata: [],
  promos: [],
  options: null,
  isOptionsAndMetadataLoading: false,
  isOptionsLoading: false,
  error: null,
};

const serviceOptionsSlice = createSlice({
  name: 'optionsMetadata',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchServiceOptionsAndMetadata.pending, (state, action) => {
      state.isOptionsAndMetadataLoading = true;
    });
    builder.addCase(fetchServiceOptionsAndMetadata.fulfilled, (state, action) => {
      state.isOptionsAndMetadataLoading = false;
      state.error = null;
      state.metadata = action.payload.metadata;
      state.options = action.payload.options;
      state.promos = action.payload.promos;
    });
    builder.addCase(fetchServiceOptionsAndMetadata.rejected, (state, action) => {
      state.isOptionsAndMetadataLoading = false;
      state.error = action.error;
    });

    builder.addCase(fetchServiceOptions.pending, (state, action) => {
      state.isOptionsLoading = true;
    });
    builder.addCase(fetchServiceOptions.fulfilled, (state, action) => {
      state.isOptionsLoading = false;
      state.error = null;
      state.options = action.payload;
    });
    builder.addCase(fetchServiceOptions.rejected, (state, action) => {
      state.isOptionsLoading = false;
      state.error = action.error;
    });
  },
});

export const selectServiceOptionsMetadata = (state: RootState) =>
  state.serviceOptions.metadata;

export const selectServiceOptions = (state: RootState) => state.serviceOptions.options;

export const selectServiceOptionsPromos = (state: RootState) =>
  state.serviceOptions.promos;

export const selectServiceOptionsAndMetadataLoading = (state: RootState) =>
  state.serviceOptions.isOptionsAndMetadataLoading;

export const selectServiceOptionsLoading = (state: RootState) =>
  state.serviceOptions.isOptionsLoading;

export const reducer = serviceOptionsSlice.reducer;
