import moment, { DurationInputArg2, Moment } from 'moment';
import { DATE_RANGE_OPTIONS } from '../config/base';

export function getLastFullWeekRange() {
  const start = moment().subtract(1, 'week').startOf('week').startOf('day');
  const end = moment().subtract(1, 'week').startOf('week').startOf('day').endOf('week');
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  return {
    raw: {
      start,
      end,
    },
    formatted: {
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
    },
  };
}

type RangeParam = {
  start: Moment;
  end: Moment;
};

export const getRange = (
  selected: keyof typeof DATE_RANGE_OPTIONS,
  rangeParam?: RangeParam,
  amount = 7,
  unit = 'd',
) => {
  const { dateRange } = DATE_RANGE_OPTIONS[selected];
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  const range = Object.assign({}, rangeParam);

  const format = (val: Moment) => val.format(DATE_FORMAT).toString();

  const currentStartDate = dateRange.start(range?.start);
  const currentEndDate = dateRange.end(range?.end);

  const result = {
    current: {
      start: format(currentStartDate),
      end: format(currentEndDate),
      rawStart: currentStartDate,
      rawEnd: currentEndDate,
    },
    history: getHistoryRangeEx(currentStartDate, currentEndDate, amount, unit),
  };

  return result;
};

export const getRangeUTC = (
  selected: keyof typeof DATE_RANGE_OPTIONS,
  rangeParam?: RangeParam,
  amount = 7,
  unit = 'd',
) => {
  const { dateRangeUTC } = DATE_RANGE_OPTIONS[selected];
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  const range = Object.assign({}, rangeParam);

  const format = (val: Moment) => val.format(DATE_FORMAT).toString();

  const currentStartDate = dateRangeUTC.start(range?.start);
  const currentEndDate = dateRangeUTC.end(range?.end);

  const result = {
    current: {
      start: format(currentStartDate),
      end: format(currentEndDate),
      rawStart: currentStartDate,
      rawEnd: currentEndDate,
    },
    history: getHistoryRangeEx(currentStartDate, currentEndDate, amount, unit),
  };

  return result;
};

export const getHistoryRangeEx = (
  currentStartDate: Moment,
  currentEndDate: Moment,
  amount: number,
  unit: string,
) => {
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  const start = moment(currentStartDate).subtract(amount, unit as DurationInputArg2);
  const end = moment(currentEndDate)
    .subtract(amount, unit as DurationInputArg2)
    .endOf('day');

  return {
    start: start.format(DATE_FORMAT),
    end: end.format(DATE_FORMAT),
    rawStart: start,
    rawEnd: end,
  };
};
