import createSagaMiddleware from 'redux-saga';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { all } from 'redux-saga/effects';
import authMiddleware from 'store/middlewares/auth';
import { reducer as authReducer, saga as authSaga } from 'store/modules/auth';
import {
  reducer as dashboardReducer,
  saga as dashboardSaga,
} from 'store/modules/dashboard';
import { reducer as settingReducer } from 'store/modules/setting';
import { reducer as servicesReducer } from 'store/modules/services';
import { reducer as originsReducer, saga as originsSaga } from 'store/modules/origins';
import {
  reducer as certificatesReducer,
  saga as certificatesSaga,
} from 'store/modules/certificates';

import { reducer as apiTokensReducer } from 'store/modules/apiTokens';
import { reducer as serviceOptionsReducer } from 'store/modules/serviceOptions';
import { reducer as uiReducer } from 'store/modules/ui';
import { reducer as accountReducer } from 'store/modules/account';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, authMiddleware];

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer, // throws non-serializable value error
  setting: settingReducer,
  services: servicesReducer,
  origins: originsReducer,
  certificates: certificatesReducer,
  apiTokens: apiTokensReducer,
  serviceOptions: serviceOptionsReducer,
  ui: uiReducer,
  account: accountReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares), // temporariry disable serializableCheck
});

sagaMiddleware.run(function* rootSaga() {
  yield all([authSaga(), dashboardSaga(), originsSaga(), certificatesSaga()]);
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
