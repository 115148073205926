// @ts-nocheck
import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  updateOriginSuccess,
  updateOriginFail,
  fetchSingleOriginFail,
  fetchSingleOriginSuccess,
  deleteOriginSuccess,
  deleteOriginFail,
} from './reducer';
import { ORIGINS_GET_SINGLE, ORIGINS_UPDATE, ORIGINS_DELETE } from './constants';
import { fetchOrigins, fetchOriginsFail, fetchOriginsSuccess } from './slice';
import request from 'utils/request';
import { Origin } from 'models/Origin';

const doFetchOrigins = function* ({ payload = {} }) {
  const { limit = 1000 } = payload;
  const requestURL = `/origins?limit=${limit}&responseType=full`;
  const param = {
    method: 'GET',
    bearer: true,
  };

  try {
    const { data }: { data: Origin[] } = yield call(request, requestURL, param);
    const webOrigins = data.filter((d) => d.type === 'WEB');
    yield put(fetchOriginsSuccess(webOrigins));
  } catch (error) {
    toast.error('An error occurred while trying to fetch origin list');
    yield put(fetchOriginsFail());
  }
};

const doFetchSingleOrigin = function* (action) {
  const requestURL = `/origins/${action.payload}`;
  const param = {
    method: 'GET',
    bearer: true,
  };

  try {
    const res = yield call(request, requestURL, param);
    yield put(fetchSingleOriginSuccess(res));
  } catch (error) {
    yield put(fetchSingleOriginFail(error));
  }
};

const doUpdateOrigin = function* (action) {
  const { serviceId, ...originData } = action.payload;
  const requestURL = `/origins/${action.payload}`;
  const param = {
    method: 'PUT',
    bearer: true,
    data: {
      ...originData,
    },
  };

  try {
    const res = yield call(request, requestURL, param);
    yield put(updateOriginSuccess(res));
    toast('Origin updated successfully!');
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    yield put(updateOriginFail(error));
  }
};

const doDeleteOrigin = function* (action) {
  const requestURL = `/origins/${action.payload}`;
  const param = {
    method: 'DELETE',
    bearer: true,
  };

  try {
    const res = yield call(request, requestURL, param);
    yield put(
      deleteOriginSuccess({
        id: action.payload.originId,
        res,
      }),
    );
    toast('Origin deleted successfully!');
  } catch (error) {
    console.log('error', error);
    yield put(deleteOriginFail(error));
  }
};

export const saga = function* () {
  yield takeEvery(fetchOrigins.type, doFetchOrigins);
  yield takeEvery(ORIGINS_UPDATE, doUpdateOrigin);
  yield takeEvery(ORIGINS_GET_SINGLE, doFetchSingleOrigin);
  yield takeEvery(ORIGINS_DELETE, doDeleteOrigin);
};
