import qs from 'query-string';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  fetchSingleWidgetDataSuccess,
  fetchSingleWidgetDataFail,
  fetchSingleWidgetData,
  widgetDateRangeChange,
  widgetRefresh,
  getAirportsSuccess,
  getAirportsFail,
} from './reducer';
import { selectWidgetSettings, selectHistoryParams, selectInterval } from './selectors';
import {
  DASH_WIDGET_DATE_RANGE_CHANGE,
  DASH_FETCH_SINGLE_WIDGET_DATA,
  DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE,
  DASH_GLOBAL_WIDGET_REFRESH,
  DASH_WIDGET_REFRESH,
  DASH_AIRPORTS_GET,
  DASH_SET_INTERVAL,
} from './constants';
import request, { requestWithLock } from 'utils/request';
import { getRange, getRangeUTC } from 'utils/dates';
import moment from 'moment-timezone';
import { selectServicesCurrent } from '../services';
import { HITS_FIELD, TRAFFIC_FIELD } from '../../../config/base';
import { getHistoryRangeEx, getLastFullWeekRange } from '../../../utils/dates';
import {
  calculateAvg,
  calculateRatio,
  compute95,
  compute95byDate,
  groupChrByMonth,
  groupChrByPopByMonth,
  normalize95Data,
  normalizeChrData,
  normalizeData,
  normalizeDataTransfer,
  safeDiv,
  transformChr,
} from './helpers';

const doSingleWidgetDateRange = function* ({ payload }) {
  yield put(fetchSingleWidgetData({ widget: payload.widget }));
};

const doAllWidgetsDateRange = function* ({ payload }) {
  const { selected, range } = payload;
  const { byId } = yield select((state) => state.dashboard.widgets);
  const widgets = Object.values(byId);

  for (const w of widgets) {
    yield put(widgetDateRangeChange({ widget: w, selected, range }));
  }
};

const doRefreshSingleWidget = function* ({ payload }) {
  yield put(fetchSingleWidgetData({ widget: payload.widget }));
};

const doRefreshAllWidgets = function* () {
  const { byId } = yield select((state) => state.dashboard.widgets);
  const widgets = Object.values(byId);

  for (const w of widgets) {
    yield put(widgetRefresh({ widget: w }));
  }
};

const doFetchSingleWidgetData = function* ({ payload }) {
  const params = { method: 'GET', bearer: true };
  const { widget } = payload;
  const widgetSettings = yield select((state) => selectWidgetSettings(state, widget._id));
  const interval = yield select(selectInterval);
  if (!widgetSettings) return;

  const currentService = yield select(selectServicesCurrent);
  const historyParams = yield select(selectHistoryParams);
  const { selected, inputRange } = widgetSettings.dateRange;
  let { sortingEnabled, paging, sortDir, sortBy, currentPage } = widgetSettings;

  const { entity } = widget.extra.widgetSpecific || {};

  if (!entity) {
    console.log('No entity');
    yield put(
      fetchSingleWidgetDataSuccess({
        widgetId: widget._id,
        current: [],
        history: [],
      }),
    );
    return;
  }

  if (widget._id === 'drilldown:trafficTable' && interval >= 1) {
    sortBy = 'date';
  }

  let current;
  let history;

  const range = getRange(selected, inputRange, historyParams.amount, historyParams.unit);

  current = range.current;
  history = range.history;

  if (entity === 'chrData' || entity === 'chrDataByPop') {
    const rangeUtc = getRangeUTC(
      selected,
      inputRange,
      historyParams.amount,
      historyParams.unit,
    );

    current = rangeUtc.current;
    history = rangeUtc.history;
  }

  const timeSpan = current.rawEnd.diff(current.rawStart, 'milliseconds');

  const hoursDisabled =
    entity === 'chrData' || entity === 'chrDataByPop'
      ? timeSpan > 1000 * 60 * 60 * 24 * 8
      : timeSpan > 1000 * 60 * 60 * 24 * 92;

  let chrInterval = interval === 0 && !hoursDisabled ? '1h' : '1d';

  let moreParams = {};
  let historyMoreParams = {};

  if (sortingEnabled) {
    const field = widgetSettings?.mainField === 0 ? HITS_FIELD : TRAFFIC_FIELD;
    moreParams.sortBy = sortBy ? `${sortDir || ''}${sortBy}` : `${sortDir || ''}${field}`;
  }

  if (paging) {
    moreParams.limit = paging;
    moreParams.offset = (currentPage - 1) * paging;
  }

  if (
    widgetSettings.widgetId === 'drilldown:trafficTable' &&
    widgetSettings.interval === 0 &&
    widgetSettings.sortDir &&
    widgetSettings.sortBy === 'time' &&
    (selected === 'today' ||
      selected === 'last7d' ||
      selected === 'last30d' ||
      (selected === 'custom' &&
        inputRange.end.format('YYYY/MM/DD') ===
          moment().tz('America/Los_Angeles').format('YYYY/MM/DD')))
  ) {
    const currentHour = Number(moment().tz('America/Los_Angeles').format('H'));
    historyMoreParams.offset = moreParams.offset + (23 - currentHour);
  }
  // selected === 'today' &&  ? moment().tz('UTC').endOf('day')
  let queryCurrent = qs.stringify({
    from: current.start,
    to: current.end,
    ...moreParams,
  });

  let queryHistory = qs.stringify({
    from: history.start,
    to: history.end,
    ...moreParams,
    ...historyMoreParams,
  });

  let urls;
  let lastFullWeek, lastFullWeekHistory;

  const prefixUrl =
    currentService && currentService._id !== 'all'
      ? `/services/${currentService._id}`
      : '';

  switch (entity) {
    case 'lastFullWeek':
      lastFullWeek = getLastFullWeekRange();

      queryCurrent = qs.stringify({
        from: lastFullWeek.formatted.start,
        to: lastFullWeek.formatted.end,
      });

      lastFullWeekHistory = getHistoryRangeEx(
        lastFullWeek.raw.start,
        lastFullWeek.raw.end,
        historyParams.amount,
        historyParams.unit,
      );

      queryHistory = qs.stringify({
        from: lastFullWeekHistory.start,
        to: lastFullWeekHistory.end,
      });

      urls = {
        current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=time`,
        history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=time`,
      };
      break;

    case 'dataTransfer':
      // hour
      if (interval === 0) {
        urls = {
          current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=time`,
          history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=time`,
        };
        // day
      } else if (interval === 1) {
        urls = {
          current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=date`,
          history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=date`,
        };
      } else {
        // = 2 month
        urls = {
          current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=year&groupBy=month`,
          history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=year&groupBy=month`,
        };
      }
      break;

    case 'dataTransferByService':
      if (interval === 0) {
        urls = {
          current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=uid&groupBy=time`,
          history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=uid&groupBy=time`,
        };
      } else if (interval === 1) {
        urls = {
          current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=uid&groupBy=date`,
          history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=uid&groupBy=date`,
        };
      } else {
        urls = {
          current: `${prefixUrl}/reports/hod?${queryCurrent}&groupBy=uid&groupBy=year&groupBy=month`,
          history: `${prefixUrl}/reports/hod?${queryHistory}&groupBy=uid&groupBy=year&groupBy=month`,
        };
      }
      break;

    case 'countryDataX':
      urls = {
        current: `${prefixUrl}/reports/country?${queryCurrent}&groupBy=country&groupBy=date`,
        history: `${prefixUrl}/reports/country?${queryHistory}&groupBy=country&groupBy=date`,
      };
      break;

    case 'countryData':
      urls = {
        current: `${prefixUrl}/reports/country?${queryCurrent}&groupBy=country`,
        history: `${prefixUrl}/reports/country?${queryHistory}&groupBy=country`,
      };
      break;

    case 'popDataX':
      urls = {
        current: `${prefixUrl}/reports/pop?${queryCurrent}&groupBy=pop&groupBy=date`,
        history: `${prefixUrl}/reports/pop?${queryHistory}&groupBy=pop&groupBy=date`,
      };
      break;

    case 'popData':
      urls = {
        current: `${prefixUrl}/reports/pop?${queryCurrent}&groupBy=pop`,
        history: `${prefixUrl}/reports/pop?${queryHistory}&groupBy=pop`,
      };
      break;

    case 'fileData':
      urls = {
        current: `${prefixUrl}/reports/file?${queryCurrent}&groupBy=file`,
        history: `${prefixUrl}/reports/file?${queryHistory}&groupBy=file`,
      };
      break;

    case 'fileDataX':
      urls = {
        current: `${prefixUrl}/reports/file?${queryCurrent}&groupBy=date`,
        history: `${prefixUrl}/reports/file?${queryHistory}&groupBy=date`,
      };
      break;

    case 'track95Graph':
      urls = {
        current: `${prefixUrl}/reports/track95?${queryCurrent}&groupBy=date`,
        history: `${prefixUrl}/reports/track95?${queryHistory}&groupBy=date`,
      };
      break;

    case 'track95Table':
      urls = {
        current: `${prefixUrl}/reports/track95?${queryCurrent}&groupBy=date`,
        history: `${prefixUrl}/reports/track95?${queryHistory}&groupBy=date`,
      };
      break;

    case 'track95KPI':
      urls = {
        current: `${prefixUrl}/reports/track95?${queryCurrent}&groupBy=date`,
        history: `${prefixUrl}/reports/track95?${queryHistory}&groupBy=date`,
      };
      break;

    case 'chrData':
      urls = {
        current: `${prefixUrl}/reports/chr?${queryCurrent}&interval=${chrInterval}`,
        history: `${prefixUrl}/reports/chr?${queryHistory}&interval=${chrInterval}`,
      };
      break;

    case 'chrDataByPop':
      urls = {
        current: `${prefixUrl}/reports/chr?${queryCurrent}&interval=${chrInterval}&groupBy=pop`,
        history: `${prefixUrl}/reports/chr?${queryHistory}&interval=${chrInterval}&groupBy=pop`,
      };
      break;

    case 'refererData':
      urls = {
        current: `${prefixUrl}/reports/refererTld?${queryCurrent}&groupBy=refererTld`,
        history: `${prefixUrl}/reports/refererTld?${queryHistory}&groupBy=refererTld`,
      };
      break;

    case 'refererURL':
      urls = {
        current: `${prefixUrl}/reports/referer?${queryCurrent}&groupBy=referer`,
        history: `${prefixUrl}/reports/referer?${queryHistory}&groupBy=referer`,
      };
      break;

    case 'isp':
      urls = {
        current: `${prefixUrl}/reports/isp?${queryCurrent}`,
        history: `${prefixUrl}/reports/isp?${queryHistory}`,
      };
      break;

    case 'mapData':
    case 'mapDataCountries':
    case 'mapDataPops':
      // We deal with mapData below. This is a kind of hack, as the mapData entity has two (sub)entities (pop and
      // country)
      break;

    default:
      throw new Error('Invalid entity passed');
  }

  try {
    let dataCurrent, dataHistory;

    if (!entity.startsWith('mapData')) {
      const [_dataCurrent, _dataHistory] = yield all([
        call(requestWithLock, urls.current, params),
        call(requestWithLock, urls.history, params),
      ]);
      dataCurrent = _dataCurrent;
      dataHistory = _dataHistory;
    }

    if (
      dataCurrent?.data &&
      ['dataTransfer', 'dataTransferByService', 'lastFullWeek'].includes(entity)
    ) {
      let normalizationFn = normalizeData;

      if (entity === 'lastFullWeek' || interval === 0) {
        normalizationFn = normalizeDataTransfer;
      }

      dataCurrent.data = normalizationFn(
        dataCurrent.data,
        entity === 'lastFullWeek' ? lastFullWeek.raw.start : current.rawStart,
        entity === 'lastFullWeek' ? lastFullWeek.raw.end : current.rawEnd,
        interval,
      );

      dataHistory.data = normalizationFn(
        dataHistory.data,
        entity === 'lastFullWeek' ? lastFullWeekHistory.rawStart : history.rawStart,
        entity === 'lastFullWeek' ? lastFullWeekHistory.rawEnd : history.rawEnd,
        interval,
      );
    }

    if (entity === 'refererData' || entity === 'refererURL') {
      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: dataCurrent.data.map((d) => ({
            ...d,
            referer: d.referer === '://' || d.referer === '-' ? 'No Referrer' : d.referer,
          })),
          history: dataHistory.data.map((d) => ({
            ...d,
            referer: d.referer === '://' || d.referer === '-' ? 'No Referrer' : d.referer,
          })),
          meta: {
            offset: dataCurrent?.meta?.offset || 0,
            total: dataCurrent?.meta?.total || 0,
          },
          urls,
        }),
      );
    } else if (entity === 'chrData') {
      dataCurrent.data = transformChr(dataCurrent.data);
      dataHistory.data = transformChr(dataHistory.data);

      if (interval === 0) {
        dataCurrent.data = normalizeChrData(
          dataCurrent.data,
          current.rawStart,
          current.rawEnd,
        );
        dataHistory.data = normalizeChrData(
          dataHistory.data,
          history.rawStart,
          history.rawEnd,
        );
      }

      if (interval === 2) {
        dataCurrent.data = groupChrByMonth(dataCurrent.data);
        dataHistory.data = groupChrByMonth(dataHistory.data);
      }

      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: dataCurrent.data,
          history: dataHistory.data,
          meta: {
            kpi: {
              ratio: {
                current: calculateRatio(dataCurrent.data),
                history: calculateRatio(dataHistory.data),
              },
            },
          },
          urls,
        }),
      );
      // yield getChrData({
      //   urls,
      //   params,
      //   interval,
      //   widgetId: widget._id,
      //   type: 'current',
      // });
      // yield getChrData({
      //   urls,
      //   params,
      //   interval,
      //   widgetId: widget._id,
      //   type: 'history',
      // });
    } else if (entity === 'chrDataByPop') {
      let current = dataCurrent.data;
      let history = dataHistory.data;

      if (interval === 2) {
        current = groupChrByPopByMonth(current);
        history = groupChrByPopByMonth(history);
      }

      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current,
          history,
          urls,
        }),
      );
      // yield getChrDataByPop({
      //   urls,
      //   params,
      //   interval,
      //   widgetId: widget._id,
      //   type: 'current',
      // });
      // yield getChrDataByPop({
      //   urls,
      //   params,
      //   interval,
      //   widgetId: widget._id,
      //   type: 'history',
      // });
    } else if (entity === 'track95Graph') {
      dataCurrent.data = normalize95Data(
        dataCurrent.data,
        current.rawStart,
        current.rawEnd,
      );

      dataHistory.data = normalize95Data(
        dataHistory.data,
        history.rawStart,
        history.rawEnd,
      );

      const track95ComputedCurrent = compute95(dataCurrent.data);
      const track95ComputedHistory = compute95(dataHistory.data);
      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: track95ComputedCurrent.data,
          history: track95ComputedHistory.data,
          meta: {
            current: track95ComputedCurrent,
            history: track95ComputedHistory,
          },
          urls,
        }),
      );
    } else if (entity === 'track95Table') {
      dataCurrent.data = normalize95Data(
        dataCurrent.data,
        current.rawStart,
        current.rawEnd,
      );

      dataHistory.data = normalize95Data(
        dataHistory.data,
        history.rawStart,
        history.rawEnd,
      );

      const track95ComputedCurrent = compute95byDate(dataCurrent.data, interval);
      const track95ComputedHistory = compute95byDate(dataHistory.data, interval);
      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: track95ComputedCurrent,
          history: track95ComputedHistory,
          urls,
        }),
      );
    } else if (entity === 'track95KPI') {
      dataCurrent.data = normalize95Data(
        dataCurrent.data,
        current.rawStart,
        current.rawEnd,
      );

      dataHistory.data = normalize95Data(
        dataHistory.data,
        history.rawStart,
        history.rawEnd,
      );

      const current95 = compute95(dataCurrent.data);
      const history95 = compute95(dataHistory.data);

      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: current95.data,
          history: history95.data,
          meta: {
            kpi: {
              percentile95: {
                current: current95.percentile95,
                history: history95.percentile95,
              },
              average: {
                current: current95.average,
                history: history95.average,
              },
              min: {
                current: current95.min,
                history: history95.min,
              },
              max: {
                current: current95.max,
                history: history95.max,
              },
            },
          },
          urls,
        }),
      );
    } else if (
      entity === 'mapData' ||
      entity === 'mapDataCountries' ||
      entity === 'mapDataPops'
    ) {
      const countryUrls = {
        current: `${prefixUrl}/reports/country?${queryCurrent}&groupBy=country`,
        history: `${prefixUrl}/reports/country?${queryHistory}&groupBy=country`,
      };

      const popUrls = {
        current: `${prefixUrl}/reports/pop?${queryCurrent}&groupBy=pop`,
        history: `${prefixUrl}/reports/pop?${queryHistory}&groupBy=pop`,
      };

      const chrUrls = {
        current: `${prefixUrl}/reports/chr?${queryCurrent}&interval=1d&groupBy=pop`,
        history: `${prefixUrl}/reports/chr?${queryHistory}&interval=1d&groupBy=pop`,
      };

      let countryCurrent, countryHistory;
      let popCurrent, popHistory;
      let chrCurrent, chrHistory;

      if (entity === 'mapData') {
        const [
          _countryCurrent,
          _countryHistory,
          _popCurrent,
          _popHistory,
          _chrCurrent,
          _chrHistory,
        ] = yield all([
          call(requestWithLock, countryUrls.current, params),
          call(requestWithLock, countryUrls.history, params),
          call(requestWithLock, popUrls.current, params),
          call(requestWithLock, popUrls.history, params),
          call(requestWithLock, chrUrls.current, params),
          call(requestWithLock, chrUrls.history, params),
        ]);

        countryCurrent = _countryCurrent;
        countryHistory = _countryHistory;
        popCurrent = _popCurrent;
        popHistory = _popHistory;
        chrCurrent = _chrCurrent;
        chrHistory = _chrHistory;
      } else if (entity === 'mapDataCountries') {
        const [_countryCurrent, _countryHistory] = yield all([
          call(requestWithLock, countryUrls.current, params),
          call(requestWithLock, countryUrls.history, params),
        ]);

        countryCurrent = _countryCurrent;
        countryHistory = _countryHistory;

        popCurrent = { data: [] };
        popHistory = { data: [] };

        chrCurrent = { data: [] };
        chrHistory = { data: [] };
      } else if (entity === 'mapDataPops') {
        const [_popCurrent, _popHistory, _chrCurrent, _chrHistory] = yield all([
          call(requestWithLock, popUrls.current, params),
          call(requestWithLock, popUrls.history, params),
          call(requestWithLock, chrUrls.current, params),
          call(requestWithLock, chrUrls.history, params),
        ]);

        popCurrent = _popCurrent;
        popHistory = _popHistory;

        chrCurrent = _chrCurrent;
        chrHistory = _chrHistory;

        countryCurrent = { data: [] };
        countryHistory = { data: [] };
      }

      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: {
            popData: popCurrent.data,
            countryData: countryCurrent.data,
            chrData: chrCurrent.data,
          },
          history: {
            popData: popHistory.data,
            countryData: countryHistory.data,
            chrData: chrHistory.data,
          },
          urls: entity === 'mapDataCountries' ? countryUrls : popUrls,
        }),
      );
    } else if (
      entity === 'dataTransfer' ||
      entity === 'dataTransferByService' ||
      entity === 'fileDataX' ||
      entity === 'lastFullWeek'
    ) {
      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: dataCurrent.data.map((v) => ({
            ...v,
            avg_request_size: safeDiv(v[TRAFFIC_FIELD], v[HITS_FIELD]),
            month: v.month && v.year ? `${v.month}, ${v.year}` : '',
          })),
          history: dataHistory.data.map((v) => ({
            ...v,
            avg_request_size: safeDiv(v[TRAFFIC_FIELD] / v[HITS_FIELD]),
            month: v.month && v.year ? `${v.month}, ${v.year}` : '',
          })),
          meta: {
            total: dataCurrent.meta.total,
            offset: dataCurrent.meta.offset,
            kpi: {
              avg_request_size: {
                current: calculateAvg(dataCurrent.data),
                history: calculateAvg(dataHistory.data),
              },
            },
          },
          urls,
        }),
      );
    } else {
      // if (entity !== 'fileData' && entity !== 'refererURL') {
      //   dataCurrent.data = normalizeData(
      //     dataCurrent.data,
      //     current.rawStart,
      //     current.rawEnd,
      //   );

      //   dataHistory.data = normalizeData(
      //     dataHistory.data,
      //     history.rawStart,
      //     history.rawEnd,
      //   );
      // }

      yield put(
        fetchSingleWidgetDataSuccess({
          widgetId: widget._id,
          current: dataCurrent.data,
          history: dataHistory.data,
          meta: {
            total: dataCurrent.meta.total,
            offset: dataCurrent.meta.offset,
          },
          urls,
        }),
      );
    }
  } catch (error) {
    console.log('ERROR', entity, error);
    yield put(fetchSingleWidgetDataFail(error));
  }
};

const doFetchAirports = function* () {
  // const requestURL = '/static/airports?jmp=@';
  const requestURL =
    '/static/airports?jmp=[keys(@),values(@)|@[*].{city:city,continent:continent,continentCode:continentCode,country:country,latitude:latitude,longitude:longitude,name:name}]';

  const param = {
    method: 'GET',
    bearer: true,
  };

  try {
    const data = yield call(request, requestURL, param);
    const airports = data[0].reduce((acc, cur, i) => {
      acc[cur] = data[1][i];
      return acc;
    }, {});
    yield put(getAirportsSuccess(airports));
  } catch (error) {
    yield put(getAirportsFail(error));
  }
};

export const saga = function* () {
  yield takeEvery(DASH_FETCH_SINGLE_WIDGET_DATA, doFetchSingleWidgetData);
  yield takeEvery(DASH_WIDGET_DATE_RANGE_CHANGE, doSingleWidgetDateRange);
  yield takeLatest(DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE, doAllWidgetsDateRange);
  yield takeEvery(DASH_WIDGET_REFRESH, doRefreshSingleWidget);
  yield takeEvery(DASH_GLOBAL_WIDGET_REFRESH, doRefreshAllWidgets);
  yield takeEvery(DASH_SET_INTERVAL, doRefreshAllWidgets);
  yield takeLeading(DASH_AIRPORTS_GET, doFetchAirports);
};
