import { HITS_FIELD, TRAFFIC_FIELD } from 'config/base';
import { Chr, Track95, Traffic } from 'models/Entities';
import moment, { Moment } from 'moment';
import { groupBy, sumBy } from 'utils/helpers';
import { abortControllers } from 'utils/request';

type BitRate = [string, number, boolean];

export const compute95 = (data: Track95[]) => {
  let bitRate: BitRate[] = [];
  let percentile95 = 0;
  let average = 0;
  let max = 0;
  let min = 0;
  let index95 = 0;

  for (let i = 0; i < data.length; i++) {
    const rate = (data[i].gigs / 300) * 8000000;
    bitRate.push([data[i].date, rate, Boolean(data[i].missed)]);
  }

  const actualBitRate = bitRate.filter((d) => !d[2]);

  if (actualBitRate.length > 0) {
    const percentile = [...bitRate].filter((d) => !d[2]).sort((a, b) => a[1] - b[1]);
    index95 = Math.min(Math.ceil(percentile.length * 0.95), percentile.length - 1);
    average = sumBy(bitRate, 1) / percentile.length;
    percentile95 = percentile[index95][1];
    max = percentile[percentile.length - 1][1];
    min = percentile[0][1];
  }

  return {
    min,
    max,
    percentile95,
    average,
    data: bitRate.map((d) => {
      return {
        date: d[0],
        value: d[1],
        missed: d[2],
      };
    }),
  };
};

const criterea = (date: string, interval: number) => {
  const splitted = date.split('T');
  if (interval === 0) {
    return `${splitted[0]}-${splitted[1].slice(0, 2)}`;
  } else if (interval === 1) {
    return splitted[0];
  } else {
    return splitted[0].slice(0, 7);
  }
};

export const compute95byDate = (data: Track95[], interval: number) => {
  const groupedData: Record<string, Track95[]> = groupBy(data, (d: Track95) =>
    criterea(d.date, interval),
  );
  return Object.values(groupedData).map((d) => compute95(d));
};

const pad = (v: number) => (v < 10 ? `0${v}` : `${v}`);

export const normalizeData = (
  originalData: Traffic[],
  startDate: Moment,
  endDate: Moment,
  interval: 1 | 2,
) => {
  const data = originalData.slice().map((d) => {
    return {
      ...d,
      time: d.date || `${d.year}-${pad(d.month!)}-01T00:00:00.000Z`,
    };
  });

  let dateScan = startDate;
  if (interval === 2) {
    return data;
  }
  do {
    const curDateString = dateScan.format().substring(0, 10);

    if (!data.find((d) => d.time.substring(0, 10) === curDateString)) {
      data.push({
        date: curDateString + 'T00:00:00.000Z',
        time: curDateString + 'T00:00:00.000Z',
        [HITS_FIELD]: 0,
        [TRAFFIC_FIELD]: 0,
        missed: true,
      });
    }
    dateScan = dateScan.add(1, 'day');
  } while (dateScan.isSameOrBefore(endDate));

  data.sort((a, b) => {
    const dir = -1;
    if (a.time <= b.time) return dir;
    return -dir;
  });

  return data;
};

// normalize hourly data, for chr miss

export const normalizeDataTransfer = (
  originalData: Traffic[],
  startDate: Moment,
  endDate: Moment,
) => {
  let dateScan = startDate;
  const data = originalData.slice();
  do {
    const curDateString = dateScan.format().substring(0, 10);

    for (let i = 0; i < 24; i++) {
      if (
        !data.find(
          (d) =>
            d.time.substring(0, 10) === curDateString &&
            d.time.substring(11, 13) === pad(i),
        )
      ) {
        data.push({
          date: curDateString + 'T00:00:00.000Z',
          time: `${curDateString}T${pad(i)}:00:00.000Z`,
          missed: true,
          [HITS_FIELD]: 0,
          [TRAFFIC_FIELD]: 0,
        });
      }
    }

    dateScan = dateScan.add(1, 'day');
  } while (dateScan.isSameOrBefore(endDate));

  data.sort((a, b) => {
    const dir = -1;
    if (a.time <= b.time) return dir;
    return -dir;
  });

  return data;
};

export const normalizeChrData = (
  originalData: Chr[],
  startDate: Moment,
  endDate: Moment,
) => {
  let dateScan = startDate;

  const data = originalData.slice();
  do {
    const curDateString = dateScan.format().substring(0, 10);

    for (let i = 0; i < 24; i++) {
      if (
        !data.find(
          (d) =>
            d.time.substring(0, 10) === curDateString &&
            d.time.substring(11, 13) === pad(i),
        )
      ) {
        data.push({
          time: `${curDateString}T${pad(i)}:00:00.000Z`,
          missed: true,
          hit: 0,
          miss: 0,
          total: 0,
          ratio: 0,
          city: '',
          country: '',
          pop: '',
          region: '',
        });
      }
    }

    dateScan = dateScan.add(1, 'day');
  } while (dateScan.isSameOrBefore(endDate));

  data.sort((a, b) => {
    const dir = -1;
    if (a.time <= b.time) return dir;
    return -dir;
  });

  return data;
};

export const normalize95Data = (
  originalData: Track95[],
  startDate: Moment,
  endDate: Moment,
) => {
  let dateScan = startDate;

  const data = originalData.slice();
  do {
    const curDateString = dateScan.format().substring(0, 10);
    for (let i = 0; i < 288; i++) {
      const hour = Math.floor(i / 12);
      const minute = (i - hour * 12) * 5;

      if (
        !data.find((d) => {
          return (
            d.date.substring(0, 10) === curDateString &&
            d.date.substring(11, 13) === pad(hour) &&
            d.date.substring(14, 16) === pad(minute)
          );
        })
      ) {
        data.push({
          date: `${curDateString}T${pad(hour)}:${pad(minute)}:00.000Z`,
          missed: true,
          gigs: 0,
        });
      }
    }

    dateScan = dateScan.add(1, 'day');
  } while (dateScan.isSameOrBefore(endDate));

  data.sort((a, b) => {
    const dir = -1;
    if (a.date <= b.date) return dir;
    return -dir;
  });

  return data;
};

export const transformChr = (data: Chr[]) =>
  data.map((d) => ({
    ...d,
    total: d.hit + d.miss,
    ratio: (d.hit / (d.hit + d.miss)) * 100 || 0,
  }));

export const groupChrByMonth = (data: Chr[] = []) => {
  const dataObj = data.reduce((acc: Record<string, Chr[]>, cur) => {
    const date = moment.tz(cur.time, 'UTC').startOf('month').toString();

    acc[date] = acc[date] ? [...acc[date], cur] : [cur];
    return acc;
  }, {});

  const dataByMonth = Object.keys(dataObj).map((entry) => {
    const totalHit = sumBy(dataObj[entry], 'hit');
    const totalMiss = sumBy(dataObj[entry], 'miss');
    const totalTotal = sumBy(dataObj[entry], 'total');
    const totalRatio = sumBy(dataObj[entry], 'ratio') / dataObj[entry].length;

    return {
      ...dataObj[entry][0],
      hit: totalHit,
      miss: totalMiss,
      total: totalTotal,
      ratio: totalRatio,
    };
  });

  return dataByMonth;
};

export const groupChrByPopByMonth = (data: Chr[] = []) => {
  const dataObj = data.reduce((acc: Record<string, Chr[]>, cur) => {
    acc[cur.pop] = acc[cur.pop] ? [...acc[cur.pop], cur] : [cur];
    return acc;
  }, {});

  const groupByData = Object.keys(dataObj).map((entry) => {
    return groupChrByMonth(dataObj[entry]);
  });

  return groupByData.flat();
};

export const calculateRatio = (data: Chr[]) => {
  const totalHit = data.reduce((acc, elem) => acc + elem.hit, 0);
  const totalMiss = data.reduce((acc, elem) => acc + elem.miss, 0);
  return totalHit === 0 ? 0 : totalHit / (totalHit + totalMiss);
};

export const safeDiv = (n: number, d: number) => (n !== 0 ? n / d : 0);

export const calculateAvg = (data: Traffic[]) => {
  const totalHit = data.reduce((acc, elem) => acc + elem[HITS_FIELD], 0);
  const totalGigs = data.reduce((acc, elem) => acc + elem[TRAFFIC_FIELD], 0);
  return safeDiv(totalGigs, totalHit);
};

export const cancelRequests = () => {
  Object.keys(abortControllers).forEach((c) => {
    if (c.includes('reports') || c.includes('airports')) {
      abortControllers[c].abort('component has been unmounted');
    }
  });
};
