import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import update from 'immutability-helper';
import { produce } from 'immer';
import { AUTH_SIGNOUT_USER } from 'store/modules/auth';
import {
  DASH_GLOBAL_AUTO_REFRESH_CHANGE,
  DASH_WIDGET_DATE_RANGE_CHANGE,
  DASH_FETCH_SINGLE_WIDGET_DATA,
  DASH_INIT_WIDGET,
  DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE,
  DASH_WIDGET_AUTO_REFRESH_CHANGE,
  DASH_GLOBAL_WIDGET_REFRESH,
  DASH_WIDGET_REFRESH,
  DASH_SET_SHOW_HISTORY,
  DASH_SET_HISTORY_PARAMS,
  DASH_WIDGET_UPDATE_SETTINGS,
  DASH_DESTROY_WIDGET,
  DASH_AIRPORTS_GET,
  DASH_SET_INTERVAL,
} from './constants';
import { successAction, failAction } from 'utils/state-helpers';
import { getRange } from 'utils/dates';
import { cancelRequests } from './helpers';

/**
 * Action creators
 */

export const fetchSingleWidgetData = createAction(DASH_FETCH_SINGLE_WIDGET_DATA);
export const fetchSingleWidgetDataSuccess = createAction(
  successAction(DASH_FETCH_SINGLE_WIDGET_DATA),
);
export const fetchSingleWidgetDataFail = createAction(
  failAction(DASH_FETCH_SINGLE_WIDGET_DATA),
);

export const widgetDateRangeChange = createAction(DASH_WIDGET_DATE_RANGE_CHANGE);
export const globalWidgetDateRangeChange = createAction(
  DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE,
);
export const widgetAutoRefreshChange = createAction(DASH_WIDGET_AUTO_REFRESH_CHANGE);

export const globalWidgetRefresh = createAction(DASH_GLOBAL_WIDGET_REFRESH);
export const widgetRefresh = createAction(DASH_WIDGET_REFRESH);
export const widgetUpdateSettings = createAction(DASH_WIDGET_UPDATE_SETTINGS);

export const globalAutoRefreshChange = createAction(DASH_GLOBAL_AUTO_REFRESH_CHANGE);

export const initWidget = createAction(DASH_INIT_WIDGET);
export const destroyWidget = createAction(DASH_DESTROY_WIDGET);

export const setShowHistory = createAction(DASH_SET_SHOW_HISTORY);
export const setHistoryParams = createAction(DASH_SET_HISTORY_PARAMS);

export const setGlobalInterval = createAction(DASH_SET_INTERVAL);

export const getAirports = createAction(DASH_AIRPORTS_GET);
export const getAirportsSuccess = createAction(successAction(DASH_AIRPORTS_GET));
export const getAirportsFail = createAction(failAction(DASH_AIRPORTS_GET));

const currentUser = JSON.parse(sessionStorage.getItem('auth'));

function getDefaultValues(user) {
  let defaultValues = {
    dateRange: {
      global: { selected: 'last7d', range: getRange('last7d') },
    },
    interval: 1,
  };

  if (!user) {
    return defaultValues;
  }

  const createdAt = user.user.createdAt;
  // if user does not have data for the last 7 days (because he was registered later) show him today's data
  if (moment().diff(createdAt, 'days') < 8) {
    defaultValues = {
      dateRange: {
        global: { selected: 'today', range: getRange('today') },
      },
      interval: 0,
    };
  }

  return defaultValues;
}

const initialState = {
  dashboards: [],
  currentDashboard: null,
  isRefreshingGlobally: false,
  dateRange: getDefaultValues(currentUser).dateRange,
  autoRefresh: {
    global: false,
  },
  showHistory: true,
  historyParams: {
    amount: 7,
    unit: 'days',
  },
  widgets: {
    byId: {},
    allIds: [],
    asyncStatus: {},
  },
  widgetSettings: {},
  widgetsData: {
    data: {},
    error: null,
    fetching: false,
    config: {},
  },
  interval: getDefaultValues(currentUser).interval,
  airports: {},
  fetchingAirpots: false,
  // widgetUrls
};

/**
 * Reduer
 */
export const reducer = handleActions(
  {
    [DASH_INIT_WIDGET]: (state, { payload }) => {
      const widget = Object.assign({}, payload);

      if (!widget.extra.dateRange) {
        widget.extra = {
          ...widget.extra,
          dateRange: {
            selected: state.dateRange.global.selected,
            name: state.dateRange.global.selected,
            range: getRange(state.dateRange.global.selected),
            inputRange: state.dateRange.global.inputRange,
          },
        };
      }

      return produce(state, (draft) => {
        draft.widgets.byId[payload._id] = payload;
        draft.widgets.allIds.push(widget._id);
        draft.widgetSettings[widget._id] = {
          mainField: 1,
          dateRange: widget.extra.dateRange,
          autoRefresh: false,
          currentPage: 1,
          sortingEnabled: widget.extra.sortingEnabled,
          sortBy: widget.extra.sortBy,
          sortDir: widget.extra.sortDir,
          paging: widget.extra.paging,
        };
      });
    },

    [DASH_DESTROY_WIDGET]: (state, { payload }) => {
      cancelRequests();
      const widgetId = payload;
      return produce(state, (draft) => {
        delete draft.widgets.byId[widgetId];
        draft.widgets.allIds = draft.widgets.allIds.filter((id) => id !== widgetId);
        delete draft.widgetSettings[widgetId];
      });
    },

    [DASH_WIDGET_UPDATE_SETTINGS]: (state, { payload }) => {
      return {
        ...state,
        widgetSettings: {
          ...state.widgetSettings,
          [payload.widgetId]: {
            ...state.widgetSettings[payload.widgetId],
            ...payload,
            ...(payload.paging && { currentPage: 1 }),
            ...('sortDir' in payload && { currentPage: 1 }),
          },
        },
      };
    },

    [DASH_WIDGET_AUTO_REFRESH_CHANGE]: (state, { payload }) => {
      const { widget, enabled } = payload;
      const widgetId = widget._id;

      return update(state, {
        widgetSettings: {
          [widgetId]: {
            autoRefresh: { $set: enabled },
          },
        },
      });
    },

    [DASH_WIDGET_DATE_RANGE_CHANGE]: (state, { payload, type }) => {
      const { widget, selected, name } = payload;
      const widgetId = widget._id;
      const range = getRange(selected, payload.range);

      let interval = 1;
      const timeSpan = range.current.rawEnd.diff(range.current.rawStart, 'milliseconds');

      if (timeSpan <= 1000 * 60 * 60 * 24 * 1) {
        interval = 0;
      }

      if (timeSpan > 1000 * 60 * 60 * 24 * 31) {
        interval = 2;
      }

      return {
        ...state,
        interval,
        widgetSettings: {
          ...state.widgetSettings,
          [widgetId]: {
            ...state.widgetSettings[widgetId],
            dateRange: {
              ...state.widgetSettings[widgetId]?.dateRange,
              selected,
              name,
              range,
              inputRange: payload.range,
            },
          },
        },
      };
    },

    [DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE]: (state, { payload, type }) => {
      const { selected, range, name } = payload;

      const newRange = getRange(selected, range);

      return update(state, {
        dateRange: {
          [name]: {
            $set: {
              ...payload,
              range: newRange,
              inputRange: range,
            },
          },
        },
      });
    },

    [AUTH_SIGNOUT_USER]: (state, { payload, type }) => ({
      ...initialState,
      status: type,
    }),

    [DASH_SET_SHOW_HISTORY]: (state, { payload }) => ({
      ...state,
      showHistory: payload,
    }),

    [DASH_SET_HISTORY_PARAMS]: (state, { payload }) => ({
      ...state,
      historyParams: payload,
    }),

    [DASH_FETCH_SINGLE_WIDGET_DATA]: (state, { payload, type }) => {
      return produce(state, (draft) => {
        // Avoid updating state of a widget which does not exist anymore
        if (!draft.widgetSettings[payload.widget._id]) return;

        draft.status = type;
        draft.widgetSettings[payload.widget._id].lastFetchSource =
          payload.lastFetchSource;
        draft.widgetSettings[payload.widget._id].loading = true;
        // ...state,
        // status: type,
        // widgetSettings: {
        //   ...state.widgetSettings,
        //   [payload.widget._id]: {
        //     ...state.widgetSettings[payload.widget._id],
        //     lastFetchSource: payload.lastFetchSource,
        //     loading: true,
        //   },
        // },
      });
    },

    [failAction(DASH_FETCH_SINGLE_WIDGET_DATA)]: (state, { payload, type }) => ({
      ...state,
      status: type,
      widgetSettings: {
        ...state.widgetSettings,
        [payload.widgetId]: {
          ...state.widgetSettings[payload.widgetId],
          loading: false,
        },
      },
    }),

    [successAction(DASH_FETCH_SINGLE_WIDGET_DATA)]: (state, { payload, type }) => {
      return produce(state, (draft) => {
        if (!draft.widgetSettings[payload.widgetId]) return;

        draft.status = type;
        draft.widgetSettings[payload.widgetId].loading = false;
        draft.widgetSettings[payload.widgetId].data = {
          current: payload.current,
          history: payload.history,
          meta: payload.meta,
        };
        draft.widgetSettings[payload.widgetId].urls = payload.urls;
        //   const { widgetId, current, history, meta, urls } = payload;
        //   if (!draft.widgetSettings[widgetId]) return;

        //   draft.widgetSettings[widgetId].loading = false;

        //   if (!draft.widgetSettings[widgetId].data) {
        //     draft.widgetSettings[widgetId].data = {
        //       current: [],
        //       history: [],
        //     };
        //   }

        //   if (current) {
        //     draft.widgetSettings[widgetId].data.current = current;
        //   }

        //   if (history) {
        //     draft.widgetSettings[widgetId].data.history = history;
        //   }

        //   if (meta) {
        //     draft.widgetSettings[widgetId].data.meta = meta;
        //   }

        //   if (urls) {
        //     draft.widgetSettings[widgetId].urls = urls;
        //   }
        // });
      });
    },

    [DASH_SET_INTERVAL]: (state, action) => ({
      ...state,
      interval: action.payload,
    }),

    [DASH_AIRPORTS_GET]: (state) => ({
      ...state,
      fetchingAirpots: true,
      error: null,
    }),

    [failAction(DASH_AIRPORTS_GET)]: (state, action) => ({
      ...state,
      fetchingAirpots: false,
      error: 'Failed to load airports data',
    }),

    [successAction(DASH_AIRPORTS_GET)]: (state, action) => ({
      ...state,
      fetchingAirpots: false,
      airports: action.payload,
    }),
  },
  initialState,
);
